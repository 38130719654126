






















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsInvestmentsAllianzViewModel
  from '@/vue-app/view-models/components/contract-savings/investments/contract-savings-investments-allianz-view-model';

@Component({
  name: 'Investments',
  components: {
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class Investments extends Vue {
  investments_allianz_view_model = Vue.observable(new ContractSavingsInvestmentsAllianzViewModel());

  @Watch('investments_allianz_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  prevStep() {
    this.$emit('prevStep');
  }

  nextStep() {
    const save_step = this.investments_allianz_view_model.saveStep();
    if (save_step) {
      this.$emit('nextStep');
    }
  }

  created() {
    this.investments_allianz_view_model.small_screen = getScreenSizeVariant() === 'small';
    this.investments_allianz_view_model.initialize();
  }
}

